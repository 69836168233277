import logo from './logo.svg';
import './App.css';
import React, { Component } from 'react'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

var WORKERS_ENDPOINT = "https://my-worker.andru-stefanescu.workers.dev/posts"

function App() {
  return (
    <Router>
      <div>
        <nav>
          <p>
            <Link to="/">Comments Section</Link>
          </p>
          <p>
            <Link to="/post">Post comment</Link>
          </p>
        </nav>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path="/">
            <AsyncComponent />
          </Route>

          <Route exact path="/post">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Home() {
  return (
    <div>
      <form onSubmit={() => { return false; }}>
        <label htmlFor="uname">Username:</label>
        <input type="text" id="uname" name="uname" />
        <label htmlFor="title">Title:</label>
        <input type="text" id="title" name="title" />
        <label htmlFor="content">Post Content:</label>
        <textarea type="text" id="content" name="content" rows="10" cols="80" />
        <button type="button" onClick={postComment}>Post comment</button>
      </form>
    </div>
  );
}

function postComment() {
  let username = document.getElementById("uname").value;
  let title = document.getElementById("title").value;
  let contents = document.getElementById("content").value;

  if (username.length == 0 || title.length == 0 || contents.length == 0) {
    alert("Please input some text for every field")
    return
  }

  let json = new Object()
  json.username = username
  json.title = title
  json.contents = contents

  fetch(WORKERS_ENDPOINT, { method: "POST", body: JSON.stringify(json) }).then(response => {
    document.location.href='/'
  })

}

function getComments() {
  return fetch(WORKERS_ENDPOINT).then(response => response.json()).then(resp => {
    return resp
  })
}

class AsyncComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      comments: []
    };
  }

  componentDidMount() {
    getComments().then(comments => {
      console.log(comments)
      this.setState({ comments: comments });
    }
    )
  }

  render() {
    return (
      <div>
        {this.state.comments.map(mapping => (
          <div className="post">
            <div className="title">
              {JSON.parse(mapping).title}
            </div>
            <div className="username">
              {JSON.parse(mapping).username} wrote:
            </div>
            <div className="contents">
              {JSON.parse(mapping).contents}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

export default App;
